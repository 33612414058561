/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql } from "gatsby"
import { Homepage } from "../../../components/homepage"

export const pageQuery = graphql`
  query malaga {
    file(relativePath: { eq: "parking-malaga-costa-del-sol-front.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 585, height: 439)
      }
    }
    posts: allMarkdownRemark(
      sort: { order: ASC, fields: [frontmatter___order_parking] }
      filter: { frontmatter: { template: { eq: "parking-post" } } }
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            slug
            title
            isParkVia
            doNotShowOnMainPage
            featuredImage {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, width: 345, height: 260)
              }
            }
          }
        }
      }
    }
  }
`

const frontmatter = {
  template: "index-page",
  slug: "/",
  title: "Parkingi Lotnisko Malaga Hiszpania",
  tagline: "Malaga, Hiszpania",
  featuredImage: "/assets/parking-malaga-costa-del-sol-front.jpeg",
  cta: {
    ctaText: "Wyszukaj parking",
    ctaLink: "#search",
  },
}
const HomePage = ({ data }) => (
  <Homepage
    frontmatter={frontmatter}
    data={data}
    descriptionComponent={
      <p>
        <strong>Adres:</strong> Avenida Comandante García Morato, s/n, 29004
        Málaga
        <p>
          Wybierając się w podróż z lotniska Malaga Costa del Sol, potrzebujesz
          pewności, że Twój samochód zostanie bezpiecznie zaparkowany. Nasza
          oferta parkingów przy tym lotnisku to gwarancja bezpieczeństwa, wygody
          i atrakcyjnych cen.
        </p>
      </p>
    }
  >
    <h2 id="parking-lotnisko-malaga-hiszpania">
      Parking Lotnisko Malaga Hiszpania
    </h2>
    <h2 id="wprowadzenie">Wprowadzenie</h2>
    <p>
      Lotnisko Málaga-Costa del Sol, znane również jako Lotnisko Pablo Picasso,
      to główna brama do słynnej Costa del Sol i reszty Andaluzji. Znajdując się
      zaledwie 7 km na południowy zachód od centrum Malagi, oferuje liczne
      udogodnienia dla podróżnych, w tym parking.
    </p>
    <h2 id="dlaczego-warto-wybrać-nasz-parking-na-lotnisku-w-maladze">
      Dlaczego warto wybrać nasz parking na lotnisku w Maladze?
    </h2>
    <h2 id="dlaczego-wybrać-nasz-parking">Dlaczego Wybrać Nasz Parking?</h2>
    <ul>
      <li>
        <strong>Różnorodność Opcji:</strong> Od niskokosztowych miejsc
        parkingowych, przez długoterminowe, po VIP.
      </li>
      <li>
        <strong>Lokalizacja:</strong> Parkingi znajdują się zarówno wewnątrz,
        jak i na zewnątrz terenu lotniska Malaga.
      </li>
      <li>
        <strong>Bezpieczeństwo:</strong> Oferujemy parkingi z całodobową ochroną
        i monitoringiem.
      </li>
      <li>
        <strong>Elastyczność:</strong> Brak opłat rezerwacyjnych i możliwość
        darmowego anulowania rezerwacji.
      </li>
    </ul>
    <p>
      Oferujemy różne opcje parkowania, od krótkoterminowego po długoterminowe,
      dostosowane do Twoich potrzeb.
    </p>
    <h3 id="opinie-klientów">
      <strong>Opinie Klientów:</strong>
    </h3>
    <p>
      Nasi klienci doceniają nasze usługi, co potwierdzają ich opinie. Średnia
      ocena parkingów przy lotnisku Malaga Costa del Sol to aż 4.8/5!
    </p>
    <hr />
    <h3 id="jak-to-działa">
      <strong>Jak to działa?</strong>
    </h3>
    <ol>
      <li>Wybierz datę przyjazdu i wyjazdu.</li>
      <li>Porównaj dostępne oferty parkingów.</li>
      <li>Zarezerwuj miejsce online i skorzystaj z atrakcyjnych rabatów.</li>
      <li>Zaparkuj swój samochód i ciesz się podróżą!</li>
    </ol>
    <hr />
    <h3 id="o-lotnisku-malaga-costa-del-sol">
      <strong>O lotnisku Malaga Costa del Sol:</strong>
    </h3>
    <p>
      Lotnisko Malaga Costa del Sol to jedno z najważniejszych lotnisk w
      Hiszpanii, obsługujące liczne połączenia krajowe i międzynarodowe.
      Znajduje się zaledwie 8 km od centrum Malagi i jest doskonale
      skomunikowane z resztą regionu.
    </p>
    <hr />
    <p>
      <strong>Jak dotrzeć do lotniska Málaga-Costa del Sol</strong>
      Adres: Avenida Comandante García Morato, 29004 Málaga
    </p>
    <p>
      <strong>Komunikacja autobusowa:</strong>
    </p>
    <ol>
      <li>
        <p>
          <strong>Ekspresowy autobus lotniskowy A75</strong>:
        </p>
        <ul>
          <li>Z lotniska do Malagi: 07:00-24:00</li>
          <li>Z Malagi do lotniska: 06:25-23:30</li>
          <li>Czas podróży: 15-25 min.</li>
          <li>Cena biletu: 3 EUR (płatność u kierowcy)</li>
        </ul>
      </li>
      <li>
        <p>
          <strong>Autobus M-128 do Torremolinos i Benalmadena</strong>:
        </p>
        <ul>
          <li>Z lotniska: Pon-Pt: 09:00-17:15, Weekendy/święta: 07:00-17:15</li>
          <li>
            Z Torremolinos-Benalmádena: Pon-Pt: 09:00-20:45, Weekendy/święta:
            08:00-16:15
          </li>
          <li>Częstotliwość: co 1-2 godz.</li>
          <li>Czas podróży: 30 min. (Torremolinos), 50 min. (Benalmadena)</li>
          <li>Cena: 3,55 EUR</li>
        </ul>
      </li>
      <li>
        <p>
          <strong>Długodystansowe połączenia autobusowe</strong>:
        </p>
        <ul>
          <li>
            <p>Malaga-Granada: 10:45, 11:00, 11:30, 16:00, 18:30, 20:45</p>
          </li>
          <li>
            <p>Granada-Malaga: 13:15, 16:00, 18:30</p>
          </li>
          <li>
            <p>Czas podróży: 2:30 godz.</p>
          </li>
          <li>
            <p>Cena: 11,43 EUR (jednokierunkowy), 22,86 EUR (w obie strony)</p>
          </li>
          <li>
            <p>Malaga-Marbella:</p>
            <ul>
              <li>
                Październik-Czerwiec: 8:15, 10:15, 11:30, 12:00, 12:30, 14:15,
                16:15, 18:00, 18:50, 20:15, 22:30
              </li>
              <li>
                Lipiec-Wrzesień: 7:00, 8:15, 10:00, 10:45, 11:30, 12:30, 13:15,
                14:15, 15:30, 16:00, 17:45, 18:45, 20:00, 21:00, 22:00, 23:45
              </li>
            </ul>
          </li>
          <li>
            <p>Czas podróży: 45 min.</p>
          </li>
          <li>
            <p>Cena: 8,30 EUR</p>
          </li>
          <li>
            <p>Malaga-Estepona: 11:30, 18:50</p>
          </li>
          <li>
            <p>Czas podróży: 1:15 godz.</p>
          </li>
          <li>
            <p>Cena: 10,70 EUR</p>
          </li>
          <li>
            <p>Malaga-Algeciras: 11:30, 18:50</p>
          </li>
          <li>
            <p>Algeciras-Malaga: 07:00, 14:15</p>
          </li>
          <li>
            <p>Czas podróży: 1:55 godz.</p>
          </li>
          <li>
            <p>Cena: 19,80 EUR</p>
          </li>
        </ul>
      </li>
    </ol>
    <p>
      <strong>Kolej:</strong>
    </p>
    <ul>
      <li>
        <p>
          <strong>Połączenie z Malagą</strong>:
        </p>
        <ul>
          <li>Częstotliwość: co 30 min.</li>
          <li>Czas podróży: 8 min.</li>
          <li>Cena: 1,70-2,60 EUR</li>
        </ul>
      </li>
      <li>
        <p>
          <strong>Połączenie z Torremolinos, Benalmadena, Fuengirola</strong>:
        </p>
        <ul>
          <li>Częstotliwość: co 20 min.</li>
          <li>
            Ceny: Torremolinos: 1,75 EUR, Benalmádena: 2,00 EUR, Fuengirola:
            2,70 EUR
          </li>
          <li>
            Czas podróży: Torremolinos: 10 min., Benalmadena: 17 min.,
            Fuengirola: 35 min.
          </li>
        </ul>
      </li>
    </ul>
    <p>
      <strong>Taksówki:</strong>
    </p>
    <ul>
      <li>Strefa postojowa przed T3</li>
      <li>Cennik miejski: 0,86-1,06 EUR/km</li>
      <li>Cennik międzymiastowy: 0,58-0,70 EUR/km</li>
      <li>Kontakt: Radiotaxi: 0034 952 040 804, Unitaxi: 0034 952 333 333</li>
    </ul>
    <p>
      <strong>Dojazd samochodem:</strong>
    </p>
    <ul>
      <li>Z południa: A-7, z północy: MA-20, N-340a</li>
      <li>Współrzędne GPS: 36°40&#39;36&quot;N, 4°29&#39;29&quot;W</li>
    </ul>
    <p>
      <strong>Usługi na lotnisku:</strong>
    </p>
    <p>
      <strong>Punkty informacyjne</strong> – Lotnisko posiada kilka stanowisk,
      gdzie można uzyskać wszelkie potrzebne informacje.
    </p>
    <p>
      <strong>Dostępność dla niepełnosprawnych</strong> – Udogodnienia dla osób
      z ograniczoną mobilnością są dostępne na całym lotnisku.
    </p>
    <p>
      <strong>Restauracje i kawiarnie</strong> – W terminalach czekają na Ciebie
      różnorodne miejsca, gdzie możesz zjeść coś smacznego czy wypić kawę.
    </p>
    <p>
      <strong>Sklepy</strong> – Oferujemy różnorodne sklepy, w tym butiki i
      sklepy wolnocłowe, gdzie możesz zrobić zakupy przed wylotem.
    </p>
    <p>
      <strong>Usługi finansowe</strong> – Dostępne są kantory, oddziały banków
      takich jak Euronet i Unicaja oraz bankomaty.
    </p>
    <p>
      <strong>Przechowywanie bagażu</strong> – Możesz skorzystać z przechowalni
      w Terminalu T3.
    </p>
    <p>
      <strong>Zgubione przedmioty</strong> – Jeśli zgubiłeś coś, skontaktuj się
      z biurem rzeczy znalezionych na pierwszym piętrze Terminalu 3. Tel: +34
      952 048 837
    </p>
    <p>
      <strong>Opieka zdrowotna</strong> – W terminalu znajduje się Centrum
      Medyczne oraz apteka otwarta od 08:00 do 22:00.
    </p>
    <p>
      <strong>Miejsce do modlitwy</strong> – Kaplica wielowyznaniowa jest
      dostępna dla pasażerów w Terminalu 3 od 6:00 do 22:30.
    </p>
    <p>
      <strong>Internet</strong> – Darmowy dostęp do sieci Wi-Fi jest dostępny
      dla wszystkich pasażerów.
    </p>
    <p>
      <strong>Dodatkowe udogodnienia</strong> – Oferujemy również luksusowe
      salony VIP dla tych, którzy chcą poczekać na swój lot w komfortowych
      warunkach.
    </p>

    <p>
      Zaplanuj swój dojazd do lotniska Málaga-Costa del Sol z wyprzedzeniem i
      korzystaj z różnorodnych środków transportu dostępnych w okolicy.
    </p>
  </Homepage>
)

export default HomePage
